// Copyright (C) 2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import { getCore, Payment } from 'cvat-core-wrapper';
import { PaymentsQuery } from 'reducers';
import { filterNull } from 'utils/filter-null';
import { PaymentData } from 'components/process-payment-modal/process-payment-modal';
import { SerializedPayment } from 'cvat-core/src/server-response-types';
import moment from 'moment';

const cvat = getCore();

export enum PaymentsActionTypes {
    GET_PAYMENTS = 'GET_PAYMENTS',
    GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS',
    GET_PAYMENTS_FAILED = 'GET_PAYMENTS_FAILED',
    GET_PAYMENT_PREVIEW = 'GET_PAYMENT_PREVIEW',
    GET_PAYMENT_PREVIEW_SUCCESS = 'GET_PAYMENT_PREVIEW_SUCCESS',
    GET_PAYMENT_PREVIEW_FAILED = 'GET_PAYMENT_PREVIEW_FAILED',
    CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS',
    CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED',
    UPDATE_PAYMENT = 'UPDATE_PAYMENT',
    UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS',
    UPDATE_PAYMENT_FAILED = 'UPDATE_PAYMENT_FAILED',
    DELETE_PAYMENT = 'DELETE_PAYMENT',
    DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS',
    DELETE_PAYMENT_FAILED = 'DELETE_PAYMENT_FAILED',
}

interface PaymentsList extends Array<Payment> {
    count: number;
}

const paymentsActions = {
    getPayments: (query: Partial<PaymentsQuery>) => createAction(PaymentsActionTypes.GET_PAYMENTS, { query }),
    getPaymentsSuccess: (payments: PaymentsList) => createAction(PaymentsActionTypes.GET_PAYMENTS_SUCCESS, { payments }),
    getPaymentsFailed: (error: any) => createAction(PaymentsActionTypes.GET_PAYMENTS_FAILED, { error }),
    getPaymentPreview: (paymentID: number) => createAction(PaymentsActionTypes.GET_PAYMENT_PREVIEW, { paymentID }),
    getPaymentPreviewSuccess: (paymentID: number, preview: string) => createAction(PaymentsActionTypes.GET_PAYMENT_PREVIEW_SUCCESS, { paymentID, preview }),
    getPaymentPreviewFailed: (paymentID: number, error: any) => createAction(PaymentsActionTypes.GET_PAYMENT_PREVIEW_FAILED, { paymentID, error }),
    createPaymentSuccess: (newPayment: Payment) => createAction(PaymentsActionTypes.CREATE_PAYMENT_SUCCESS, { newPayment }),
    createPaymentFailed: (error: any) => createAction(PaymentsActionTypes.CREATE_PAYMENT_FAILED, { error }),
    updatePayment: () => createAction(PaymentsActionTypes.UPDATE_PAYMENT),
    updatePaymentSuccess: (payment: Payment) => createAction(PaymentsActionTypes.UPDATE_PAYMENT_SUCCESS, { payment }),
    updatePaymentFailed: (paymentID: number, error: any) => createAction(PaymentsActionTypes.UPDATE_PAYMENT_FAILED, { paymentID, error }),
    deletePayment: (paymentID: number) => createAction(PaymentsActionTypes.DELETE_PAYMENT, { paymentID }),
    deletePaymentSuccess: (paymentID: number) => createAction(PaymentsActionTypes.DELETE_PAYMENT_SUCCESS, { paymentID }),
    deletePaymentFailed: (paymentID: number, error: any) => createAction(PaymentsActionTypes.DELETE_PAYMENT_FAILED, { paymentID, error }),
};

export type PaymentsActions = ActionUnion<typeof paymentsActions>;

export const getPaymentsAsync =
    (query: PaymentsQuery): ThunkAction => async (dispatch) => {
        try {
            // We remove all keys with null values from the query
            const filteredQuery = filterNull(query);

            dispatch(paymentsActions.getPayments(filteredQuery));

            const queryParams: any = {
                page: filteredQuery.page,
                jobID: filteredQuery.jobID,
            };

            if (filteredQuery.sort) queryParams.sort = filteredQuery.sort;
            if (filteredQuery.paymentID) queryParams.paymentID = filteredQuery.paymentID;
            const payments = await cvat.payments.get(queryParams);

            dispatch(
                paymentsActions.getPaymentsSuccess(
                    payments.sort((a, b): number => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1)),
                ),
            );
        } catch (error) {
            console.log('PAYMENT ERR:', error);
            dispatch(paymentsActions.getPaymentsFailed(error));
        }
    };

export const getPaymentPreviewAsync =
    (payment: Payment): ThunkAction => async (dispatch) => {
        dispatch(paymentsActions.getPaymentPreview(payment.id));
        try {
            const result = await payment.frames.preview();
            dispatch(paymentsActions.getPaymentPreviewSuccess(payment.id, result));
        } catch (error) {
            dispatch(paymentsActions.getPaymentPreviewFailed(payment.id, error));
        }
    };

export const createPaymentAsync =
    (data: PaymentData): ThunkAction => async (dispatch) => {
        const serializedPayment: Partial<SerializedPayment> = {
            amount: data.amount,
            job: data.jobId ?? null,
            description: data.description,
            final: data.final,
            date: data.date ?? new Date().toLocaleString(),
        };
        const paymentInstance = new cvat.classes.Payment(serializedPayment);
        try {
            const savedPayment = await paymentInstance.save({ ...data, job: data.jobId });
            dispatch(paymentsActions.createPaymentSuccess(savedPayment));
        } catch (error) {
            dispatch(paymentsActions.createPaymentFailed(error));
            throw error;
        }
    };

export function updatePaymentAsync(paymentInstance: Payment): ThunkAction<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
        try {
            dispatch(paymentsActions.updatePayment());
            const updated = await paymentInstance.save();
            dispatch(paymentsActions.updatePaymentSuccess(updated));
        } catch (error) {
            dispatch(paymentsActions.updatePaymentFailed(paymentInstance.id, error));
            return false;
        }

        return true;
    };
}

export const deletePaymentAsync =
    (payment: Payment): ThunkAction => async (dispatch) => {
        dispatch(paymentsActions.deletePayment(payment.id));
        try {
            await payment.delete();
        } catch (error) {
            dispatch(paymentsActions.deletePaymentFailed(payment.id, error));
            return;
        }

        dispatch(paymentsActions.deletePaymentSuccess(payment.id));
    };
