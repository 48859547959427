// Copyright (C) 2022 Intel Corporation
// Copyright (C) 2022-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Card from 'antd/lib/card';
import Descriptions from 'antd/lib/descriptions';
import { MoreOutlined } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import { Job, JobStage, Task } from 'cvat-core-wrapper';
import { useCardHeightHOC } from 'utils/hooks';
import Preview from 'components/common/preview';
import JobActionsMenu from 'components/job-item/job-actions-menu';
import { Progress } from 'antd';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { PaidIcon } from 'icons';
import Text from 'antd/lib/typography/Text';

const useCardHeight = useCardHeightHOC({
    containerClassName: 'cvat-jobs-page',
    siblingClassNames: ['cvat-jobs-page-pagination', 'cvat-jobs-page-top-bar'],
    paddings: 40,
    minHeight: 200,
    numberOfRows: 3,
});

interface Props {
    job: Job;
    onJobUpdate: (job: Job) => void;
}

function JobCardComponent(props: Props): JSX.Element {
    const { job, onJobUpdate } = props;
    const taskItem: Task | undefined = useSelector((state: CombinedState) => state.tasks.current.find((task: Task) => task.id === job.taskId),
    );
    // const [expanded, setExpanded] = useState<boolean>(false);
    const history = useHistory();
    const height = useCardHeight();
    const onClick = (event: React.MouseEvent): void => {
        const url = `/tasks/${job.taskId}/jobs/${job.id}`;
        if (event.ctrlKey) {
            window.open(url, '_blank', 'noopener noreferrer');
        } else {
            history.push(url);
        }
    };

    const getProgressColor = () => {
        if (job.stage === 'acceptance') return '#52c41a';
        if (job.stage === 'validation') return '#faad14';
        return '#1677ff';
    };

    const jobStage = (): JSX.Element | string => {
        if (job.paymentsCount) {
            return (
                <Text type='success'>
                    <PaidIcon />
                    {' '}
                    &nbsp;
                    Paid
                </Text>
            );
        }
        if (job.stage === JobStage.ANNOTATION && job.assignee === null) return 'None';
        return job.stage;
    };
    useEffect(() => {
        console.log('JOB:', job);
    }, [job]);

    return (
        <Card
            // onMouseEnter={() => setExpanded(true)}
            // onMouseLeave={() => setExpanded(false)}
            style={{ height }}
            className={job.paymentsCount ? 'cvat-job-page-list-item paid' : 'cvat-job-page-list-item'}
            cover={(
                <>
                    <Preview
                        job={job}
                        onClick={onClick}
                        loadingClassName='cvat-job-item-loading-preview'
                        emptyPreviewClassName='cvat-job-item-empty-preview'
                        previewWrapperClassName='cvat-jobs-page-job-item-card-preview-wrapper'
                        previewClassName='cvat-jobs-page-job-item-card-preview'
                    />
                    <div className='cvat-job-page-list-item-id'>
                        ID:
                        {` ${job.id}`}
                    </div>
                    <div className='cvat-job-page-list-item-dimension'>{job.dimension.toUpperCase()}</div>
                </>
            )}
        >
            <Descriptions column={1} size='small' className='job-general-details-section '>
                <Descriptions.Item label='Task name' className='task-name'>
                    {taskItem?.name ?? 'Untitled Task'}
                </Descriptions.Item>
                <Descriptions.Item label='Stage'>
                    { jobStage() }
                </Descriptions.Item>

                {/* <Descriptions.Item
                    label='State'
                    className={job.state === JobState.PAID ? 'paid-job-status' : undefined}
                >
                    {job.state}
                </Descriptions.Item> */}

                <Descriptions.Item label='Assignee'>
                    <Text type={job.assignee ? 'warning' : undefined}>
                        {job.assignee ? job.assignee.username : 'No Assignee'}
                    </Text>
                </Descriptions.Item>
            </Descriptions>
            <Progress
                percent={parseFloat(((job.framesAnnotated / job.frameCount) * 100).toFixed(1))}
                success={{
                    percent: (job.framesAnnotated / job.frameCount) * 100,
                    strokeColor: getProgressColor(),
                }}
                status={job.paymentsCount ? 'success' : 'normal'}
                size='small'
            />
            <Descriptions column={1} size='small' className='job-frame-details-section'>
                <Descriptions.Item label='Total frames'>{job.stopFrame - job.startFrame + 1}</Descriptions.Item>
                <Descriptions.Item label='Annotated'>{job.framesAnnotated}</Descriptions.Item>
                <Descriptions.Item label='Unannotated'>
                    {job.stopFrame - job.startFrame + 1 - job.framesAnnotated}
                </Descriptions.Item>
            </Descriptions>
            <Dropdown
                trigger={['click']}
                destroyPopupOnHide
                overlay={<JobActionsMenu onJobUpdate={onJobUpdate} job={job} />}
            >
                <MoreOutlined className='cvat-job-card-more-button' />
            </Dropdown>
        </Card>
    );
}

export default React.memo(JobCardComponent);
